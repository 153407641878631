import React from "react"
import styled from "styled-components"
import { buttonBckg, H2, H3 } from "../../globalStyles"
import Mockup from "./Mockup"
function Work(props) {
  const {
    img,
    width,
    // link,
    // button,
    title,
    dark,
    index,
    description,
    extension,
  } = props

  let images = (
    <ImgDiv className="col-11  p-0 col-md-6 my-auto mx-auto col-lg-6 text-left ">
      <Mockup img={img} dark={dark} extension={extension} />
    </ImgDiv>
  )
  return (
    <Row
      ref={props.myRef}
      dark={dark}
      className="row justify-content-center mx-auto  text-center "
    >
      {width > 640 ? index % 2 === 0 && images : null}
      <ColMin dark={dark} text className="col-12  col-md-6 col-lg-5 text-left ">
        <H2
          values={"true"}
          about={"true"}
          dark={dark}
          className="mb-3"
          style={{
            color: dark ? buttonBckg.darkHover : "white",
          }}
        >
          {title}
        </H2>
        {/* <H3 className="mb-1">SUBTITLE</H3> */}
        <H3 dark={dark}>{description}</H3>
        {/* {button !== "-" && (
          <a href={link} target="blank">
            <LandingButton dark={!dark}>
              {button} <span>⇢</span>
            </LandingButton>
          </a>
        )} */}
      </ColMin>
      {(index % 2 === 1 && images) || (width < 641 && images)}
    </Row>
  )
}

export default Work

export const Row = styled.div`
  width: 95%;
  border-radius: 15px;
  background: ${({ dark }) => (dark ? "rgba(255,255,255,.4)" : null)};

  height: auto;
  @media (max-width: 640px) {
    margin-top: 20%;
    padding-top: 5%;
  }

  @media (min-width: 641px) {
    margin-top: 0%;
    margin-bottom: 3%;
  }

  @media (min-width: 1024px) {
  }
  opacity: 0;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
`

export const ColMin = styled.div`
  height: auto;
  @media (max-width: 640px) {
    margin: 5% 0 0 0;

    ${({ top }) => top && `margin-bottom:5%; margin-top:10%;`}
  }

  @media (min-width: 641px) {
    ${({ top }) => top && `margin-top:10%;`}
    margin:5% auto 5% auto;
  }

  @media (min-width: 1024px) {
    ${({ top }) => top && `margin-top:3%; margin-bottom:3%;`}
    ${({ top }) => !top && `margin-top:2%; `}
  }
`

export const ImgDiv = styled.div`
  @media (max-width: 640px) {
    margin: 5% 0 5% 0;
  }

  @media (min-width: 641px) {
    margin: 5% 0 5% 0;
  }

  @media (min-width: 1024px) {
    margin: 5% 0 5% 0;
  }
`
