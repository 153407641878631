import styled from "styled-components"
import { FullContainer, buttonBckg } from "../../globalStyles"

export const FullContainerLocal = styled(FullContainer)`
  /* height: 100vh; */
  height: auto;
  min-height: 100vh;
  position: relative;
  /* background: linear-gradient(
    162deg,
    rgba(74, 67, 189, 1) 0%,
    rgba(38, 138, 221, 1) 39%,
    rgba(0, 212, 255, 1) 108%,
    rgba(60, 94, 201, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  ); */

  ${({ dark }) =>
    dark
      ? `
      


    
//     background: radial-gradient(
//     ellipse at top,
// ${buttonBckg.dark} 40%,
//     #090a0f 80%
//   );
  
  background:rgba(240,240,250);
  
  
  
  
  `
      : ` 
  
         
    
    
    
  //   background: radial-gradient(ellipse at top, rgba(41, 125, 245), #090a0f 140%);
  // background: radial-gradient(
  //   ellipse at top,
  //   rgba(95, 99, 257, 1),
  //   rgba(24,43,80,1) 35%
  // );
  
 
 background: rgba(24,43,80,1);

  `}

  /* color: #505050 !important; */

  @media (max-width: 640px) {
    padding-bottom: 15px;
  }

  @media (min-width: 641px) {
  }

  @media (min-width: 1024px) {
    overflow: hidden;
  }

  ${FullContainer};
  overflow: hidden;
`

export const Planets = styled.img`
  display: block;
  position: absolute;
  z-index: 0;
  ${({ dark }) =>
    dark &&
    `

  @media (max-width: 640px) {
      opacity:0.8;
  filter: grayscale(0.4);
  margin:3% 0 0 -15%;
  width:100px;

}

@media (min-width: 641px) {
    width: 310px;
  opacity:0.8;
  filter: grayscale(0.4);
  margin:90px 0 0 -150px;

}




  animation: c 1s ease-out;
  @keyframes c {
    0% {
  transform: translate(-23px, -43px);
    }

    100% {
      transform: translate(0, 0);
    }
  } ;

 
  `}

  ${({ orange }) =>
    orange &&
    `
  @media (max-width: 640px) {
      width:100px;
      margin:61% 0 0 83%;
          opacity:0.8;
  filter: grayscale(.4);
}

@media (min-width: 641px) {
    width: 260px;
  right:0;
    opacity:0.8;
  filter: grayscale(.4);
margin:110px -120px 0 0;
}

  animation: z 1s cubic-bezier(.3, 0, .5, 1);
  @keyframes z {
    0% {
  transform: translate(70px, -50px);
    }

    100% {
      transform: translate(0, 0);
    }
  } ;

  
  `}
`
export const ColMin = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    margin: 5% 0 0 0;

    ${({ top }) => top && `margin-bottom:5%; margin-top:10%;`}
  }

  @media (min-width: 641px) {
    ${({ top }) => top && `margin-top:10%;`}
    margin-bottom:25px;
  }

  @media (min-width: 1024px) {
    ${({ top }) => top && `margin-top:3%; margin-bottom:3%;`}
    ${({ top }) => !top && `margin-top:2%; `}
    margin-bottom: 6%;
  }
`
