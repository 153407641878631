import React, { useContext, useEffect, useRef } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import Work from "./Work"
import { H1, H3, buttonBckg, StarsSix, Row } from "../../globalStyles"
import { FullContainerLocal, Planets, ColMin } from "./WorksStyle"
// import { graphql, useStaticQuery } from "gatsby"
import Footer from "../HomeSuccessSection/CustomFooter"
import useWindowSize from "../../hooks/resizeHook"
import { scrollInNatur } from "../GSAP/gsap"
function Works({ data }) {
  const { isDarkMode } = useContext(ThemeContext)
  const [width] = useWindowSize()

  let main = data.allContentfulWorksMainHU.nodes[0]

  const { para, title, svg } = main

  let worksArray = data.allContentfulWorksEachHU.nodes

  ///
  const serviceRef = useRef(null)
  serviceRef.current = []
  const addToRefs = el => {
    if (el && !serviceRef.current.includes(el)) {
      serviceRef.current.push(el)
    }
  }
  useEffect(() => {
    // if (serviceRef.current.length === 1) {
    scrollInNatur(serviceRef.current, 0, 100, 1.2)
    // }
    // console.log()

    return () => {}
  }, [width])
  ////

  let works = worksArray.map((x, i) => (
    <Work
      myRef={addToRefs}
      title={x.title}
      description={x.para.para}
      button={x.button}
      img={x.svg}
      index={i}
      key={i}
      dark={isDarkMode}
      width={width}
      link={x.link}
      extension={x.isExtension}
    />
  ))
  return (
    <FullContainerLocal dark={isDarkMode} className="container-fluid">
      <Planets
        dark={isDarkMode}
        orange
        src={svg[1].file.url}
        alt={svg[1].title}
      />
      <Planets dark src={svg[0].file.url} alt={svg[0].title} />

      {!isDarkMode && (
        <>
          <StarsSix /> <StarsSix bottom />
        </>
      )}
      <Row>
        <div className="row justify-content-center  mx-auto text-center align-items-center">
          <ColMin
            small
            top
            className="col-11 col-md-8 col-lg-7   align-self-center"
          >
            <H1
              // landing
              style={{
                color: isDarkMode ? buttonBckg.darkHover : "white",
              }}
            >
              {title}
            </H1>
            <H3
              className="mt-5"
              style={{ color: isDarkMode ? "#505050" : "white" }}
            >
              {para.para}
            </H3>
          </ColMin>
        </div>
        {works}
        <Footer footer={"true"} dark={isDarkMode} />
      </Row>
    </FullContainerLocal>
  )
}

export default Works
