import React from "react"
import { buttonBckg } from "../../globalStyles"
import styled from "styled-components"
import useToggleState from "../../hooks/useToggleState"
import Img from "gatsby-image"
function MockupComp(props) {
  const { img, dark, extension } = props

  const [clicked, toggleClicked] = useToggleState(false)

  return (
    <>
      {extension === "true" ? (
        <Screenshot
          extension
          className="col-12 mx-auto my-auto justify-content-center p-0"
          fluid={img.fluid}
          alt={img.title}
        />
      ) : (
        <MockupCol
          onClick={toggleClicked}
          className="col-12  justify-content-center  mr-auto  p-0"
        >
          <Mockup dark={dark} className="h-100 text-center mr-auto my-auto">
            <Screenshot fluid={img.fluid} alt={img.title} />

            <BrowserControls clicked={clicked} />
          </Mockup>
        </MockupCol>
      )}
    </>
  )
}

export const MockupCol = styled.div`
  /* perspective: 100vw; */

  @media (max-width: 640px) {
    perspective: none;
    padding: 0;
    margin-top: 10%;
    /* margin-bottom: 20%; */
  }

  @media (min-width: 641px) {
  }

  @media (min-width: 1024px) {
  }
`

export const Mockup = styled.div`
  /* border: solid 0px ${({ dark }) =>
    dark ? "#e8e8e8" : buttonBckg.light}; */
  border: solid 0px ${({ dark }) => (dark ? buttonBckg.dark : buttonBckg.light)};

  border-radius: 10px;
  box-shadow: 0px 5px 20px -8px ${({ dark }) => (dark ? buttonBckg.dark : buttonBckg.light)};
  transform: rotateX(0deg) rotateY(-20deg) rotateZ(0deg);
  transition: all ease 0.3s;

  &::-webkit-scrollbar {
    width: 0;
  }

  margin-left: auto;
  margin-right: auto;
  padding: 0;
  line-height: 0;

  @media (max-width: 640px) {
    transform: none;
    padding: 0px;
    /* max-height: 190px;
    max-height: ${({ clicked }) => clicked && `470px`}; */
    border: solid 0px
      ${({ dark }) => (dark ? buttonBckg.dark : buttonBckg.light)};
    border-top-width: 15px;
  }

  @media (min-width: 641px) {
    border-top-width: 25px;
  }
`

export const Screenshot = styled(Img)`
  width: 100%;
  height: auto;
  padding-bottom: 0px;
  margin: 0px;
  padding: 0;
  border-radius: 0px 0px 10px 10px;

  @media (max-width: 640px) {
    ${({ extension }) => extension && `margin-top: 8% !important;`}
  }
`
export const BrowserControls = styled.span`
  position: absolute;
  height: 10px;
  z-index: 100;
  width: 10px;
  top: -18px;
  left: 40px;
  background: #000;
  background: #fec031;
  border-radius: 50%;
  opacity: 1;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: -20px;
    background: #000;
    background: #ff6054;
    border-radius: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    right: -20px;
    background: #000;
    background: #00ce4a;
    border-radius: 50%;
  }

  @media (max-width: 640px) {
    top: 3px;
    width: 8px;
    height: 8px;
    left: 35px;
    transition: all 500ms;
  }
`

export default MockupComp
